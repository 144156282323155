import React from 'react';
import './Footer.css'; // Assuming you will create a CSS file for styling

const Footer = () => {
    return (
        <footer className="site-footer">
            <ul className="footer-links">
                <li><a href="/faq">FAQ</a></li>
                <li><a href="/terms-of-service">Terms of Service</a></li>
                <li><a href="/privacy-notice">Privacy Notice</a></li>
            </ul>
        </footer>
    );
};

export default Footer;
