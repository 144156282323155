import React from 'react';
import './HomePage.css';
import wavesImg from './assets/waves.svg';

function HomePage() {
  return (
    <div className="home-container">
      <div className="text-area1">
        <p>Infinitemusic gives you a new way to explore your musical creativity</p>
      </div>
      <div className="text-area2">
        <p>
          Get on the waitlist for our private beta <a href="https://forms.gle/sc9gvBJUE9pPQJys8"><b>here</b></a>
        </p>
      </div>
      <div className="image-area">
        <img src={wavesImg} alt="Waves" />
      </div>
    </div>
  );
}

export default HomePage;
